import { Entity } from '@backstage/catalog-model';
import { configApiRef, useApi } from '@backstage/core-plugin-api';
import {
  EntityConsumedApisCard,
  EntityProvidedApisCard,
} from '@backstage/plugin-api-docs';
import {
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityLayoutRouteProps,
  isComponentType,
} from '@backstage/plugin-catalog';
import { EntityAdrContent } from '@backstage-community/plugin-adr';
import { isAdrAvailable } from '@backstage-community/plugin-adr-common';
import { Grid } from '@material-ui/core';
import { isArgocdAvailable } from '@roadiehq/backstage-plugin-argo-cd';
import { EntityGithubInsightsContent } from '@roadiehq/backstage-plugin-github-insights';
import {
  EntityGithubPullRequestsContent,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import React, { useMemo } from 'react';

import { adrContentDecorator } from '../components/catalog/adrContentDecorator';
import {
  argoCdContent,
  ciCdContent,
  techDocsContent,
} from '../components/catalog/EntityPage/EntityContentTabs';
import { ScoreCard } from '../components/techInsights/Scorecard';
import { getFlag } from '../helpers/helpers';

type EntityKind = 'component'; // TODO DX-1124: extend with | 'resource' | 'group' | ...
const commonLayoutRoutes = {
  scorecards: {
    title: 'Scorecards',
    path: '/scorecards',
    children: <ScoreCard />,
  },
  adrs: {
    title: 'ADRs',
    path: '/adrs',
    children: <EntityAdrContent contentDecorators={[adrContentDecorator()]} />,
  },
  argoCd: {
    title: 'Argo CD',
    path: '/argocd',
    children: argoCdContent,
  },
  pullRequests: {
    title: 'Pull Requests',
    path: '/pull-requests',
    children: <EntityGithubPullRequestsContent />,
  },
  ciCd: {
    title: 'Github Actions',
    path: '/github-actions',
    children: ciCdContent,
  },
  codeInsights: {
    title: 'Code Insights',
    path: '/code-insights',
    children: <EntityGithubInsightsContent />,
  },
  api: {
    title: 'API',
    path: '/api',
    children: (
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityProvidedApisCard />
        </Grid>
        <Grid item md={6}>
          <EntityConsumedApisCard />
        </Grid>
      </Grid>
    ),
  },
  dependencies: {
    title: 'Dependencies',
    path: '/dependencies',
    children: (
      <Grid container spacing={3} alignItems="stretch">
        <Grid item md={6}>
          <EntityDependsOnComponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={6}>
          <EntityDependsOnResourcesCard variant="gridItem" />
        </Grid>
      </Grid>
    ),
  },
  techDocs: {
    title: 'Docs',
    path: '/docs',
    children: techDocsContent,
  },
};

type LayoutRouteMap = {
  [key in keyof typeof commonLayoutRoutes]: Record<
    EntityKind,
    (param: Entity) => boolean
  >;
};

export const useEntityLayoutRoutes = () => {
  const configApi = useApi(configApiRef);

  const layoutRouteEntityConditionMap = useMemo<LayoutRouteMap>(
    () => ({
      scorecards: {
        component: (_entity: Entity) =>
          getFlag(configApi, 'tech-insights') &&
          !isComponentType(['platform-service'])(_entity),
      },
      adrs: {
        component: (_entity: Entity) =>
          isAdrAvailable(_entity) && getFlag(configApi, 'adr'),
      },
      argoCd: {
        component: (_entity: Entity) =>
          isArgocdAvailable(_entity) &&
          getFlag(configApi, 'argocd') &&
          !isComponentType(['library', 'platform-service'])(_entity),
      },
      pullRequests: {
        component: (_entity: Entity) =>
          isGithubPullRequestsAvailable(_entity) &&
          !isComponentType(['platform-service']),
      },
      ciCd: {
        component: (_entity: Entity) =>
          !isComponentType(['platform-service'])(_entity),
      },
      codeInsights: {
        component: (_entity: Entity) =>
          !isComponentType(['platform-service'])(_entity),
      },
      api: {
        component: (_entity: Entity) =>
          !isComponentType(['library', 'platform-service'])(_entity),
      },
      dependencies: {
        component: (_entity: Entity) =>
          !isComponentType(['platform-service'])(_entity),
      },
      techDocs: {
        component: (_entity: Entity) => getFlag(configApi, 'techdocs'),
      },
    }),
    [configApi],
  );

  const entityLayoutRoutes = useMemo(
    () =>
      Object.entries(layoutRouteEntityConditionMap).reduce(
        (acc: EntityLayoutRouteProps[], [route, kindConditionMap]) => {
          const currentRoute =
            commonLayoutRoutes[route as keyof typeof commonLayoutRoutes];
          const shouldRender = (entity: Entity) =>
            kindConditionMap[entity.kind.toLowerCase() as EntityKind](entity);

          acc.push({
            ...currentRoute,
            if: shouldRender,
          });
          return acc;
        },
        [],
      ),
    [layoutRouteEntityConditionMap],
  );

  return entityLayoutRoutes;
};
