import type { AdrContentDecorator } from '@backstage-community/plugin-adr';
import { replace } from 'lodash';

const imageRegexp = /\!\[(.*)\]\((.*)\)/g; // ![alt-text](https://example.com/image.png)
const proxyApi = '/api/gh-media/proxy?fileUrl=';

/**
 * This function is required since the plugin has introduced a change that removes the final slash in the url.
 * Specific commit: https://github.com/backstage/backstage/commit/61f7a1911b36dd523a27297a5210f9093441b4a2
 * Introduced in version 0.6.14: https://github.com/backstage/backstage/blob/master/plugins/adr/CHANGELOG.md#0614
 */
export function addSlash(url: string): string {
  return url.endsWith('/') ? url : `${url}/`;
}

export const adrContentDecorator =
  (): AdrContentDecorator =>
  ({ baseUrl, content }) => {
    const editedContent = replace(
      content,
      imageRegexp,
      (_match, ...[title, mediaPath]: string[]) => {
        if (mediaPath.startsWith('http')) {
          return `![${title}](${mediaPath})`;
        }
        const url = addSlash(baseUrl);
        const blobUrl = replace(url, 'tree', 'blob');
        const rewrittenMediaPath = replace(mediaPath, /^\.?\//, ''); // starting with ./ or /
        const rewrittenImage = `![${title}](${proxyApi}${blobUrl}${rewrittenMediaPath})`;

        return rewrittenImage;
      },
    );

    return { content: editedContent };
  };
