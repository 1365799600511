import {
  RELATION_CHILD_OF,
  RELATION_PARENT_OF,
} from '@backstage/catalog-model';
import { EntityLayout } from '@backstage/plugin-catalog';
import {
  EntityCatalogGraphCard,
  Direction,
} from '@backstage/plugin-catalog-graph';
import { EntityOwnershipCard } from '@backstage/plugin-org';
import { EntityTeamPullRequestsContent } from '@backstage-community/plugin-github-pull-requests-board';
import { Grid } from '@material-ui/core';
import React from 'react';

import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import { EntityWarnings } from './EntityWarnings';
import { GraphNode } from '../../catalogGraph/CatalogGraphNode';
import { GroupProfileCard } from '../../org';
import { MembersListCard } from '../MembersList';

export const GroupPage = () => (
  <EntityLayoutWrapper>
    <EntityLayout.Route path="/" title="Overview">
      <Grid container spacing={3}>
        <EntityWarnings />
        <Grid item xs={12} md={6}>
          <GroupProfileCard variant="gridItem" showLinks />
        </Grid>
        <Grid item xs={12} md={6}>
          <EntityOwnershipCard variant="gridItem" />
        </Grid>
        <Grid item xs={12} md={12}>
          <MembersListCard showAggregateMembersToggle />
        </Grid>
      </Grid>
    </EntityLayout.Route>
    <EntityLayout.Route path="/org-diagram" title="Org Diagram">
      <EntityCatalogGraphCard
        variant="gridItem"
        direction={Direction.LEFT_RIGHT}
        title="Org Diagram"
        height={700}
        relations={[RELATION_CHILD_OF, RELATION_PARENT_OF]}
        kinds={['Group', 'User']}
        unidirectional
        maxDepth={1}
        renderNode={GraphNode}
      />
    </EntityLayout.Route>
    <EntityLayout.Route path="/pull-requests" title="Pull Requests">
      <EntityTeamPullRequestsContent />
    </EntityLayout.Route>
  </EntityLayoutWrapper>
);
