import { useApi, configApiRef } from '@backstage/core-plugin-api';
import {
  EntityLayout,
  EntityAboutCard,
  EntitySwitch,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
} from '@backstage/plugin-catalog';
import {
  EntityCatalogGraphCard,
  Direction,
} from '@backstage/plugin-catalog-graph';
import { EntityAdrContent } from '@backstage-community/plugin-adr';
import { isAdrAvailable } from '@backstage-community/plugin-adr-common';
import { EntityLinksCard } from '@celonis/backstage-plugin-catalog';
import { Grid } from '@material-ui/core';
import { isArgocdAvailable } from '@roadiehq/backstage-plugin-argo-cd';
import {
  isGithubPullRequestsAvailable,
  EntityGithubPullRequestsContent,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import React from 'react';

import { getFlag } from '../../../helpers/helpers';
import { GraphNode } from '../../catalogGraph/CatalogGraphNode';
import { adrContentDecorator } from '../adrContentDecorator';
import {
  argoCdContent,
  ciCdContent,
  codeInsightsContent,
  techDocsContent,
} from './EntityContentTabs';
import { EntityLayoutWrapper } from './EntityLayoutWrapper';
import { EntityWarnings } from './EntityWarnings';
import { ClusterCard } from '../ClusterCard/ClusterCard';

export const ResourcePage = () => {
  const configApi = useApi(configApiRef);

  return (
    <EntityLayoutWrapper>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          <EntityWarnings />
          <Grid item md={5}>
            <EntityAboutCard />
          </Grid>
          <Grid item md={7} xs={12}>
            <EntityCatalogGraphCard
              variant="gridItem"
              height={200}
              renderNode={GraphNode}
              direction={Direction.LEFT_RIGHT}
              unidirectional
              maxDepth={1}
            />
          </Grid>

          <Grid item md={5}>
            <EntityLinksCard variant="gridItem" />
          </Grid>
          <EntitySwitch>
            <EntitySwitch.Case if={e => e.spec?.type === 'cluster'}>
              <Grid item md={7}>
                <ClusterCard variant="gridItem" />
              </Grid>
            </EntitySwitch.Case>
          </EntitySwitch>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        if={entity => isAdrAvailable(entity) && getFlag(configApi, 'adr')}
        path="/adrs"
        title="ADRs"
      >
        <EntityAdrContent contentDecorators={[adrContentDecorator()]} />
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/argocd"
        title="Argo CD"
        if={entity => isArgocdAvailable(entity) && getFlag(configApi, 'argocd')}
      >
        {argoCdContent}
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/pull-requests"
        title="Pull Requests"
        if={isGithubPullRequestsAvailable}
      >
        <EntityGithubPullRequestsContent />
      </EntityLayout.Route>

      <EntityLayout.Route path="/github-actions" title="Github Actions">
        {ciCdContent}
      </EntityLayout.Route>

      {codeInsightsContent}

      <EntityLayout.Route path="/dependencies" title="Dependencies">
        <Grid container spacing={3} alignItems="stretch">
          <Grid item md={6}>
            <EntityDependsOnComponentsCard variant="gridItem" />
          </Grid>

          <Grid item md={6}>
            <EntityDependsOnResourcesCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route
        path="/docs"
        title="Docs"
        if={() => getFlag(configApi, 'techdocs')}
      >
        {techDocsContent}
      </EntityLayout.Route>
    </EntityLayoutWrapper>
  );
};
