import { SONARQUBE_PROJECT_KEY_ANNOTATION } from '@backstage-community/plugin-sonarqube-react';

import { ARGOCD_ANNOTATION_APP_SELECTOR } from '../catalog/ServiceAlignmentCard/constants';

export const MANDATORY_ANNOTATIONS = [
  SONARQUBE_PROJECT_KEY_ANNOTATION,
  ARGOCD_ANNOTATION_APP_SELECTOR,
];

export const EXCLUDED_ANNOTATIONS = [
  'backstage.io/managed-by-location',
  'backstage.io/managed-by-origin-location',
  'backstage.io/view-url',
  'backstage.io/edit-url',
  'backstage.io/source-location',
];
