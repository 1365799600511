/*
 * Copyright 2021 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

// FETCHED FROM https://github.com/backstage/backstage/blob/474cd71ab8af7b60d814de7493979daa1718d372/plugins/tech-insights/src/components/ScorecardsList/ScorecardsList.tsx

import { MarkdownContent } from '@backstage/core-components';
import { useApi } from '@backstage/core-plugin-api';
import { BackstageTheme } from '@backstage/theme';
import { techInsightsApiRef } from '@backstage-community/plugin-tech-insights';
import { makeStyles, List, ListItem, ListItemText } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';

import { CheckInfoDialog } from './CheckDetailsDialog/ScorecardDialog';
import {
  ExtendedCheckResponse,
  CheckResult,
  ScorecardGroupsContent,
} from './types';

const useStyles = makeStyles((theme: BackstageTheme) => ({
  listItemText: {
    paddingRight: theme.spacing(0.5),
    fontSize: '0.7rem',
  },
  inlineElements: {
    display: 'flex',
  },
}));

export const ScorecardsList = (props: {
  checkResults: CheckResult[];
  group: ScorecardGroupsContent;
}) => {
  const { checkResults, group } = props;
  const classes = useStyles();
  const api = useApi(techInsightsApiRef);

  const types = [...new Set(checkResults.map(({ check }) => check.type))];
  const checkResultRenderers = api.getCheckResultRenderers(types);

  return (
    <List>
      {checkResults.map((result, index) => {
        const description = checkResultRenderers.find(
          renderer => renderer.type === result.check.type,
        )?.description;

        const component = checkResultRenderers.find(
          renderer => renderer.type === result.check.type,
        )?.component;

        const renderedResult = component ? (
          component(result)
        ) : (
          <Alert severity="error">Unknown type.</Alert>
        );

        const checkResponse = result.check as ExtendedCheckResponse;
        const dialogIcon = <CheckInfoDialog result={result} group={group} />;
        const checkTitle = <>{`${index + 1}. ${checkResponse.name}`}</>;
        const renderedDescription = description ? (
          description(result)
        ) : (
          <MarkdownContent content={checkResponse.description} />
        );

        const listItemKey: string = `${checkResponse.id}-${index}`;
        const listItemId = `${listItemKey}-id`;
        return (
          <ListItem key={listItemId}>
            <ListItemText
              key={listItemKey}
              primary={checkTitle}
              secondary={
                <div className={classes.inlineElements}>
                  {[renderedDescription, dialogIcon]}
                </div>
              }
              className={classes.listItemText}
            />
            {renderedResult}
          </ListItem>
        );
      })}
    </List>
  );
};
